@import url('https://fonts.googleapis.com/css2?family=Anton&family=Nunito:wght@900&family=Rowdies:wght@300&family=Unbounded:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Play:wght@700&display=swap');





/* END MEDIA */
.App{
    background-repeat: repeat;
    background-size: 100% 100%;
    color: black;
    background-color: white;

}

.roadmap{
    font-family: Play, serif;
    font-size: 60pt;

}

.roadmapText{
    font-family: Play, serif;

    font-size: 15pt;
}
.background{
    background-color: #f8f8f8;
}

.orangeBox{
    width:75%;
    height:75%;
    border-radius: 5px;
    border:1px solid coral ;
    background-color: coral;
    -moz-box-shadow: 0 25px 25px coral;
    -webkit-box-shadow: 0 25px 25px coral;
    box-shadow: 0 25px 25px coral;
    transition: all 0.5s ease;
    transform: scale(1);
}

.orangeBox:hover {
    background-color: #f39c7a;
    box-shadow: 0px 20px 20px  #f39c7a;
    border-color:  #f39c7a;
    transform: scale(1.05);
}


.yellowBox{
    width:75%;
    height:75%;
    border-radius: 5px;
    border:1px solid gold;
    background-color: gold;
    -moz-box-shadow: 0 25px 25px gold;
    -webkit-box-shadow: 0 25px 25px gold;
    box-shadow: 0 25px 25px gold;
    transition: all 0.5s ease;
    transform: scale(1);
}

.yellowBox:hover{
    background-color: yellow;
    box-shadow: 0px 20px 20px yellow;
    border-color: yellow;
    transform: scale(1.05);
}

.blueBox{
    width:75%;
    height:75%;
    border-radius: 5px;
    border:1px solid cornflowerblue ;
    background-color: cornflowerblue;
    -moz-box-shadow: 0 25px 25px cornflowerblue;
    -webkit-box-shadow: 25px 0 25px cornflowerblue;
    box-shadow: 0 25px 25px cornflowerblue;
    transition: all 0.5s ease;
    transform: scale(1);
}

.blueBox:hover{
    background-color: #8fb4fd;
    box-shadow: 0px 20px 20px #8fb4fd;
    border-color: #8fb4fd;
    transform: scale(1.05);
}

.purpleBox{
    width:75%;
    height:75%;

    border:1px solid rebeccapurple ;
    background-color: rebeccapurple;
    border-radius: 5px;
    -moz-box-shadow: 0 25px 25px rebeccapurple;
    -webkit-box-shadow: 0 25px 25px rebeccapurple;
    box-shadow: 0 25px 25px rebeccapurple;
    transition: all 0.5s ease;
    transform: scale(1);
}

.purpleBox:hover{
    background-color: mediumpurple;
    box-shadow: 0px 20px 20px mediumpurple;
    border-color: mediumpurple;
    transform: scale(1.05);
}


.container{
    vertical-align: center;
    text-align: center;
    min-height:100px;
    margin-top:3%;
    width:100%;


}
#detail{
    padding: 1%;
}

#examples{
    padding: 1%;
}

#roadmap{
    padding: 1%;
}
.h1{
    padding: .1em;
    margin: 0;
    font-size: 30pt;
    color: black;
    align-self: center;
    font-family: "Unbounded", serif;
    display: inline-block;
}

.main{
    color: #fd6b02;
}
.h1detail{
    font-size: 11pt;
    font-family: "Unbounded", serif;
    font-weight: 100;
    color: #333232;
    text-align: left;
}

ul{
    list-style: none;
}
a{
    text-decoration: none;
}
*{
    box-sizing: border-box;
}
button{
    cursor: pointer;
    outline: none;
    border: none;
}

footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.footer-left, .footer-middle, .footer-right {
    width: 33%;
    text-align: center;
}
.footer-middle a {
    margin: 0 10px;
    color: #fff;
}
.scrolling-content{
    height: 100%;
}

/* ALL UL LI AND NAV CLASSES */
nav ul li a:hover{
    background-color: #fd6b02;
    color:#111111;
    box-shadow: 5px 10px 30px rgba(198, 120, 64, 0.41);
    transition: all ease 0.2s;
}

nav{
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-self: center;

    text-align: center;

    height: 75px;
    z-index: 1;
    background-color: white;
    cursor: pointer;
}
nav ul{
    display: flex;
    align-self: center;

}
.active{
    border-color: #fd6b02;
    color:#000000;
    transition: all ease 0.2s;
}
nav ul li a{
    font-family: Nunito, serif;
    height:auto;
    text-align: center;
    line-height: 40px;
    margin: 3px;
    padding: 0 15px;
    display: flex;
    font-size: 0.8rem;
    text-transform: uppercase;

    justify-content: space-around;
    align-items: center;
    align-self: center;

    font-weight: 500;
    color:black;
    letter-spacing: 1px;
    border-radius: 3px;
    transition: 0.2s ease-in-out;

}